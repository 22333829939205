import React, { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import useAuthContext from 'hooks/useAuthContext';
import styled from '@emotion/styled';
import { Button } from 'components';
import { formatCurrency } from 'utils/rifmFormatters';
import {
  ConatactRequestIcon,
  ListingAddedIcon,
  OfferAddedIcon,
  ScheduleViewRequestIcon,
  UserIcon
} from './notificationIcons';

interface NotificationProps {
  data: NotificationPageItem;
  onRequestViewClick: (data: NotificationPageItem) => void;
  onReassignClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onEditOfferClick: (value: NotificationPageItem) => void;
  onClickListingAgreement: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Notification: React.FC<NotificationProps> = ({
  data,
  onRequestViewClick,
  onReassignClick,
  onEditOfferClick,
  onClickListingAgreement
}) => {
  const [, setListingId] = useQueryParam('listingId', StringParam);
  const [, setWorkspaceTab] = useQueryParam('workspaceTab', StringParam);
  const {
    formData,
    email,
    name,
    phone,
    offerAmount,
    type,
    listingAddress,
    listingId,
    title
  } = data;

  const icon = useMemo(() => {
    switch (type) {
      case 'contactRequest':
        return <ConatactRequestIcon className="notification__img" />;
      case 'createOffer':
        return <OfferAddedIcon className="notification__img" />;
      case 'createListing':
        return <ListingAddedIcon className="notification__img" />;
      case 'scheduleRequest':
        return <ScheduleViewRequestIcon className="notification__img" />;
      case 'userSignedUp':
        return <UserIcon className="notification__img" />;
      default:
        return <div className="notification__img" />;
    }
  }, [type]);

  const { authData } = useAuthContext();

  const text = useMemo(() => {
    switch (type) {
      case 'contactRequest':
        return `${name} ⋅ ${email} ⋅ ${phone}`;
      case 'createOffer':
        return offerAmount ? `${formatCurrency(offerAmount)} ⋅ ${listingAddress}` : listingAddress;
      case 'scheduleRequest':
        return `${listingAddress} ⋅ ${name} ⋅ ${email} ⋅ ${phone}`;
      case 'userSignedUp':
        return `${formData.user?.name} ⋅ ${formData.user?.email} ⋅ ${formData.user?.phone}`;
      case 'createListing':
      case 'rejectServiceOrdered':
      default:
        return listingAddress;
    }
  }, [email, listingAddress, name, offerAmount, phone, type, formData.user]);

  const handleViewClick = (type: NotificationType) => {
    if (type === 'contactRequest') {
      onRequestViewClick(data);
      return;
    }
    const tabName = type === 'createOffer' ? 'offers' : 'information';
    setListingId(listingId, 'replaceIn');
    setWorkspaceTab(tabName, 'replaceIn');
  };

  return (
    <StyledNotification className="notification">
      <div className="notification__info">
        {icon}
        <div>
          {title}
          <div className="notification__listing">{text}</div>
        </div>
      </div>
      {data.reassignedToName && (
        <div className="notification__reasign">Reassigned to {data.reassignedToName}</div>
      )}
      <div className="notification__btn-group">
        {/* {['createListing', 'contactRequest'].includes(type) && ( */}
        {['contactRequest'].includes(type) && (
          <Button className="notification__btn" onClick={() => handleViewClick(type)}>
            View →
          </Button>
        )}
        {!data.reassignedToId && type === 'createListing' && (
          <>
            {authData?.superAdmin && <Button className="notification__btn" onClick={onClickListingAgreement}>
              Check and sign →
            </Button>}
            {authData?.superAdmin && <Button className="notification__btn" onClick={onReassignClick}>
              Reassign
            </Button>}
          </>
        )}
        {type === 'createOffer' && (
          <>
            {/* <Button className="notification__btn" onClick={() => handleViewClick(type)}>
              View →
            </Button> */}
            <Button className="notification__btn" onClick={() => onEditOfferClick(data)}>
              Check and fill the offer →
            </Button>
            {!data.reassignedToId && (
              <Button className="notification__btn" onClick={onReassignClick}>
                Reassign
              </Button>
            )}
          </>
        )}
        {type === 'userSignedUp' && formData.companyManagerSigningLink && (
          <a className="notification__btn" href={formData.companyManagerSigningLink} title="Sign">
            Sign
          </a>
        )}
      </div>
    </StyledNotification>
  );
};

export default Notification;

const StyledNotification = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;

  & ~ & {
    border-top: 1px solid ${props => props.theme.colors.seashell};
  }

  .notification {
    &__info {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
    &__img {
      width: 24px;
      height: 24px;
      margin: 0 13px 0 0;
      flex-shrink: 0;
    }
    &__listing {
      color: ${props => props.theme.colors.grayDark};
    }
    &__btn {
      display: inline-block;
      padding: 0 8px;
      height: 24px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      background-color: ${props => props.theme.colors.red};
      border-radius: ${props => props.theme.misc.borderRadius};
      text-decoration: none;
      & ~ .notification__btn {
        margin: 0 0 0 8px;
      }
    }
    &__reasign {
      background: ${props => props.theme.colors.seashell};
      color: ${props => props.theme.colors.red};
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      padding: 0 8px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .notification__reasign {
      margin-right: 8px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    padding: 16px 0;
    align-items: flex-start;

    .notification {
      &__info {
        align-items: flex-start;
      }
      &__reasign {
        margin-left: 30px;
      }
      &__btn {
        margin: 8px 8px 0 0;

        & ~ .notification__btn {
          margin: 8px 8px 0 0;
        }
      }
      &__btn-group {
        margin: 8px 0 0 38px;
      }
    }
  }
`;
